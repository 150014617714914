// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Divider } from 'antd';
import * as tableListSelector from 'redux/selectors/tableList';
import * as invoiceAction from 'redux/actions/invoice';
import * as listActions from 'redux/actions/list';
import { Link } from 'react-router-dom';
import { toPrice, toDateString } from 'Utils/utils';
import { WrapperTable } from 'components';
import style from './invoiceList.module.less';
import * as queryString from 'query-string';

const Fragment = React.Fragment;

class InvoiceList extends Component {
  componentWillUnmount() {
    this.props.unload();
  }

  render() {
    const columns = [
      {
        title: 'Order #',
        dataIndex: 'orderCode',
        defaultSortOrder: 'descend',
        sorter: true
      },
      {
        title: 'Invoice Date',
        dataIndex: 'invoiceDate',
        sorter: true,
        render: d => toDateString(d)
      },
      {
        title: 'Order Date',
        dataIndex: 'orderDate',
        sorter: true,
        render: d => toDateString(d)
      },
      {
        title: 'Name',
        dataIndex: 'customerFirstName',
        render: (customerFirstName, obj) => {
          return `${customerFirstName} ${obj.customerLastName}`;
        },
        sorter: true
      },
      {
        title: 'Shipping Amt',
        dataIndex: 'shippingAmt',
        render: shippingAmt => {
          return toPrice(shippingAmt);
        },
        sorter: true
      },
      {
        title: 'Taxes',
        dataIndex: 'taxes',
        render: taxes => {
          return toPrice(taxes);
        },
        sorter: true
      },
      {
        title: 'Total',
        dataIndex: 'total',
        className: 'money',
        render: total => {
          return toPrice(total);
        },
        sorter: true
      },
      {
        title: 'Status',
        dataIndex: ['status', 'name'],
        sortKey: 'status',
        sorter: true
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (id, row) => (
          <Fragment>
            <Link to={`/sales/invoices/${id}/?showArchive=${this.props.showArchive}`}>view</Link>
            <Divider type="vertical" />
            {!this.props.showArchive && row.status && row.status.id === 'DIMENSION_ADDED' ? (
              <Link to={`/sales/invoices/approve/${id}/`}>approve</Link>
            ) : (
              <div></div>
            )}
          </Fragment>
        )
      }
    ];
    const defaultFilters = this.props.showArchive
      ? {
          archive: {
            _field: 'archive',
            operator: 'IS',
            value: 'archived'
          }
        }
      : {};
    return (
      <div className={style.container}>
        <Row style={{ marginBottom: '16px' }}>
          <Col span={24}>
            <WrapperTable
              title="Invoice List"
              key={this.props.showArchive}
              defaultFilters={defaultFilters}
              filters={filters}
              columns={columns}
              data={this.props.listData}
              api={this.props.searchInvoice}
            />
          </Col>
        </Row>
        {!this.props.showArchive && (
          <Row>
            <Col span={24}>
              <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                If you want to see archived orders, please <Link to="/sales/invoices?showArchive=true">click here</Link>
              </span>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const filters = [
  {
    field: 'code',
    type: 'TEXT'
  },
  {
    field: 'orderCode',
    type: 'TEXT'
  },
  {
    field: 'invoiceDate',
    type: 'DATE',
    operator: 'BETWEEN'
  },
  {
    field: 'orderDate',
    type: 'DATE',
    operator: 'BETWEEN'
  },

  {
    field: 'total',
    type: 'NUMBER',
    operator: 'BETWEEN'
  },
  {
    field: 'subTotal',
    type: 'NUMBER',
    operator: 'BETWEEN'
  },
  {
    field: 'shippingAmt',
    type: 'NUMBER',
    operator: 'BETWEEN'
  },
  {
    field: 'taxes',
    type: 'NUMBER',
    operator: 'BETWEEN'
  },
  {
    field: 'customerFirstName',
    type: 'TEXT'
  },
  {
    field: 'customerLastName',
    type: 'TEXT'
  },
  {
    field: 'customerEmail',
    type: 'TEXT'
  },
  {
    field: 'status',
    type: 'OPTION',
    options: [
      { id: 'DRAFT', name: 'Draft' },
      { id: 'DIMENSION_ADDED', name: 'Dimension Added' },
      { id: 'UNPAID', name: 'Unpaid' },
      { id: 'PAID', name: 'Paid' },
      { id: 'CANCELLED', name: 'Cancelled' }
    ]
  }
];

const mapDispatchToProps = {
  searchInvoice: invoiceAction.searchInvoice,
  unload: listActions.listUnload
};

const mapStateToProps = (state, props) => {
  return {
    listData: tableListSelector.listData(state),
    showArchive: queryString.parse(props.location.search).showArchive === 'true'
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);
